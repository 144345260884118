<template>
  <Modal
    :visible="visible"
    @closeModal="closeModal"
    :header="true"
    :footer="false"
    width="50%"
  >
    <template v-slot:header>
      <Loading :visible="loading" />
      {{ $t('actionlog.create_action') }}
    </template>

    <template v-slot:body v-if="!loading">
      <div class="description" v-if="mapObject">
        {{ $t('actionlog.create_action_description') }}
        <span v-if="mapObject.mapObjectType && mapObject.mapObjectType.data">
          {{ mapObject.mapObjectType.data.name }}
        </span>
        <span> </span>
        <span v-if="mapObject.mapObjectLabel && mapObject.mapObjectLabel.data">
          {{ mapObject.mapObjectLabel.data.label }}
        </span>
        <span v-else>
          {{ mapObject.name }}
        </span>
      </div>
      <div class="description" v-else>
        {{ $t('actionlog.create_action_on_map_description') }}
      </div>
      <div class="action-types">
        <Accordeon
          v-for="(category, id) in dynamicActionTypeCategories"
          :key="id"
          :title="category.name">
          <ul class="types">
            <li class="type" v-for="(type, index) in dynamicActionTypesByCategory(id)" :key="index">
              <a class="action-type-link" @click="createActionForActionType(type.id)">
                {{ type.name }}
              </a>
            </li>
          </ul>
        </Accordeon>
        <div v-if="Object.keys(dynamicActionTypeCategories).length === 0"
             class="no-action-types description">
          {{ $t('actionlog.create_action_no_types_description') }}
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>

import { mapGetters } from 'vuex';
import MapObjectTypeApi from '@/api/map_object_type';

import Accordeon from '@/components/Accordeon.vue';
import Loading from '@/components/Loading.vue';
import Modal from '@/components/Layout/Modal.vue';

export default {
  name: 'SelectActionTypeToCreateModal',
  components: {
    Accordeon,
    Loading,
    Modal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mapObject: {
      type: Object,
      required: false,
    },
    ownGeo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      dynamicActionTypeCategories: {},
      dynamicActionTypes: [],
      loading: false,
      icon: {},
      mapObjectTypeId: null,
      label: '',
    };
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
      originalDynamicActionTypes: 'settings/getDynamicActionTypes',
      originalDynamicActionTypeCategories: 'settings/getDynamicActionTypeCategories',
    }),
    DATsOwnGeo() {
      return Object.values(this.originalDynamicActionTypes)
        .filter(item => item.own_geo);
    },
    DATsOwnGeoOnNetwork() {
      return Object.values(this.originalDynamicActionTypes)
        .filter(item => item.own_geo)
        .filter(item => item.on_network);
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    createActionForActionType(id) {
      this.$emit('closeModal');
      this.$emit('createActionForActionType', id);
    },
    getDynamicActionTypes() {
      this.loading = true;
      const mapObjectTypeId = this.mapObject.map_object_type_id;
      MapObjectTypeApi.getDynamicActionTypesById(mapObjectTypeId, 'dynamicActionTypeCategory')
        .then((response) => {
          this.dynamicActionTypes = response.data.filter(type => type.own_geo === this.ownGeo);
          this.dynamicActionTypeCategories = {};
          this.dynamicActionTypes.forEach((value) => {
            const category = value.dynamicActionTypeCategory.data;
            this.dynamicActionTypeCategories[category.id] = category;
          });
          this.loading = false;
        });
    },
    getDynamicActionTypesWithOwnGeoOnNetwork() {
      this.loading = true;
      this.dynamicActionTypes = this.DATsOwnGeoOnNetwork;
      this.dynamicActionTypeCategories = {};
      this.dynamicActionTypes.forEach((value) => {
        // eslint-disable-next-line max-len
        const category = this.originalDynamicActionTypeCategories[value.dynamic_action_type_category_id];
        this.dynamicActionTypeCategories[category.id] = category;
      });
      this.loading = false;
    },
    dynamicActionTypesByCategory(id) {
      return this.dynamicActionTypes.filter(
        type => parseInt(type.dynamic_action_type_category_id, 10) === parseInt(id, 10),
      ).filter(type => type.own_geo === this.ownGeo);
    },
    getIcon() {
      const mapObjectTypeId = this.mapObject.map_object_type_id;
      this.icon = this.mapObjectTypes[mapObjectTypeId].icon.data;
    },
    initData() {
      if (this.mapObject) {
        this.getDynamicActionTypes();
        this.getIcon();
      } else {
        this.getDynamicActionTypesWithOwnGeoOnNetwork();
      }
    },
  },
  watch: {
    mapObject() {
      this.initData();
    },
    ownGeo() {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style scoped>
  .header-icon {
    border-right: 1px solid #42464c;
    padding-right: 10px;
    margin-right: 10px;
  }
  .description {
    margin-bottom: 20px;
    color: #42464c;
  }

  ul.types {
    margin: 0;
    padding-left: 20px;
  }
  ul.types li.type {
    list-style: disc;
  }
  a.action-type-link {
    color: #1890ff;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  a.action-type-link:hover {
    text-decoration: none;
    color: #42464c;
  }
  div.no-action-types {
    font-style: italic;
  }
</style>

/* eslint-disable no-shadow,no-param-reassign,no-return-assign,no-sequences */
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import NetworkApi from '@/api/network';
import EventBus from '@/events/event-bus';
import mapFunctions from '@/mixins/mapFunctions';

const CACHE_TIME = (60000 * 3);

const state = {
  updated_at: null,
  display_actions: true,
  points: {},
  lines: {},
  polygons: {},
  actions: {},
  mode: null,
  filter: {},
  drawing: {},
  selected: [],
};

const transformLines = (lines) => {
  lines = lines.map((item) => {
    if (item.geo === null || item.geo === undefined) {
      item.geo = mapFunctions.wktMultilineToArray(item.coords);
    }
    return item;
  });
  return lines;
};

const actions = {
  fetchAllObjectsOnMap({ commit }, { zoom, bbox = null }) {
    EventBus.$emit('Map.loading', true);
    console.log('fetchAllObjectsOnMap', state.filter, zoom, bbox);
    if (bbox === null) {
      return NetworkApi.getObjectsOnMap(localStorage.getItem('network_id'), zoom, state.filter)
        .then((data) => {
          if (data && data.points && data.lines && data.actions) {
            data.lines = transformLines(data.lines);
            commit('SET_POINTS', { points: data.points, zoom, bbox });
            commit('SET_LINES', data.lines);
            commit('SET_POLYGONS', data.polygons);
            commit('SET_ACTIONS', data.actions);
          }
          return data;
        })
        .catch(() => {
        })
        .finally(() => {
          EventBus.$emit('Map.loading', false);
        });
    }
    return NetworkApi.getObjectsInBoundingBox(localStorage.getItem('network_id'), bbox[0], bbox[1], bbox[2], bbox[3], zoom, state.filter)
      .then((data) => {
        if (data && data.points && data.lines && data.actions) {
          data.lines = transformLines(data.lines);
          commit('SET_POINTS', { points: data.points, zoom, bbox });
          commit('SET_LINES', data.lines);
          commit('SET_POLYGONS', data.polygons);
          commit('SET_ACTIONS', data.actions);
        }
        return data;
      })
      .catch(() => {
      })
      .finally(() => {
        EventBus.$emit('Map.loading', false);
      });
  },
  fetchPointsOnMap({ commit }, { zoom, bbox = null, clustering = true }) {
    if (state.points !== null && Object.hasOwn(state.points, zoom)
      && Object.hasOwn(state.points[zoom], 'updated_at')
      && state.points[zoom].updated_at > (Date.now() - CACHE_TIME)
      && state.points[zoom].bbox === bbox
    ) {
      return state.points[zoom].data;
    }
    EventBus.$emit('Map.loading', true);
    if (bbox === null) {
      return NetworkApi.getPointsOnMap(localStorage.getItem('network_id'), zoom, clustering, state.filter)
        .then((points) => {
          if (points) {
            commit('SET_POINTS', { points, zoom });
          }
          return points;
        })
        .catch(() => {
        })
        .finally(() => {
          EventBus.$emit('Map.loading', false);
        });
    }
    return NetworkApi.getPointsInBoundingBox(localStorage.getItem('network_id'), bbox, zoom, clustering, state.filter)
      .then(points => points)
      .catch(() => {
      })
      .finally(() => {
        EventBus.$emit('Map.loading', false);
      });
  },
  fetchLinesOnMap({ commit }, { zoom, bbox }) {
    EventBus.$emit('Map.loading', true);
    if (bbox === null) {
      if (state.lines !== null && state.lines.updated_at > (Date.now() - CACHE_TIME)
      ) {
        EventBus.$emit('Map.loading', false);
        return false;
      }
      return NetworkApi.getLinesOnMap(localStorage.getItem('network_id'), zoom, state.filter)
        .then((lines) => {
          lines = transformLines(lines);
          if (lines) {
            commit('SET_LINES', lines);
          }
          return lines;
        })
        .catch(() => {
        })
        .finally(() => {
          EventBus.$emit('Map.loading', false);
        });
    }
    return NetworkApi.getLinesInBoundingBox(localStorage.getItem('network_id'), bbox[0], bbox[1], bbox[2], bbox[3], zoom, state.filter)
      .then((lines) => {
        lines = transformLines(lines);
        if (lines) {
          commit('SET_LINES', lines);
        }
        return lines;
      })
      .catch(() => {
      })
      .finally(() => {
        EventBus.$emit('Map.loading', false);
      });
  },
  fetchPolygonsOnMap({ commit }, { zoom, bbox }) {
    EventBus.$emit('Map.loading', true);
    if (bbox === null) {
      if (state.polygons !== null && state.polygons.updated_at > (Date.now() - CACHE_TIME)
      ) {
        EventBus.$emit('Map.loading', false);
        return false;
      }
      return NetworkApi.getPolygonsOnMap(localStorage.getItem('network_id'), zoom, state.filter)
        .then((polygons) => {
          if (polygons) {
            commit('SET_POLYGONS', polygons);
          }
          return polygons;
        })
        .catch(() => {
        })
        .finally(() => {
          EventBus.$emit('Map.loading', false);
        });
    }
    return NetworkApi.getPolygonsInBoundingBox(localStorage.getItem('network_id'), bbox[0], bbox[1], bbox[2], bbox[3], zoom, state.filter)
      .then(polygons => polygons)
      .catch(() => {
      })
      .finally(() => {
        EventBus.$emit('Map.loading', false);
      });
  },
  fetchActionsOnMap({ commit }) {
    if (state.actions !== null && state.actions.updated_at > (Date.now() - CACHE_TIME)
    ) {
      return false;
    }
    EventBus.$emit('Map.loading', true);
    return NetworkApi.getActionsOnMap(localStorage.getItem('network_id'), state.filter)
      .then((actions) => {
        if (actions) {
          commit('SET_ACTIONS', actions);
        }
        return actions;
      })
      .catch(() => {
      })
      .finally(() => {
        EventBus.$emit('Map.loading', false);
      });
  },
  setFilter({ commit }, { filter }) {
    commit('SET_FILTER', filter);
    commit('RESET_MAP');
  },
  resetFilter({ commit }) {
    commit('SET_FILTER', {});
    commit('RESET_MAP');
  },
  showActions({ commit }) {
    commit('SET_DISPLAY_ACTIONS', true);
  },
  hideActions({ commit }) {
    commit('SET_DISPLAY_ACTIONS', false);
  },
  setSelected({ commit }, { mapObjects }) {
    commit('SET_SELECTED', mapObjects);
  },
  reverseSelected({ commit }) {
    const { selected } = state;
    commit('SET_SELECTED', selected.reverse());
  },
  addSelected({ commit }, { mapObject }) {
    // console.log('map/addSelected', mapObject.id, mapObject);
    const { selected } = state;
    selected.push(mapObject);
    commit('SET_SELECTED', selected);
  },
  removeSelected({ commit }, { mapObjectId }) {
    // console.log('map/removeSelected', mapObjectId);
    const selected = state.selected.filter(item => item.id !== mapObjectId);
    commit('SET_SELECTED', selected);
  },
  resetSelected({ commit }) {
    // console.log('map/resetSelected');
    commit('SET_SELECTED', []);
  },
  resetMap({ commit }) {
    commit('RESET_MAP');
  },
};

const mutations = {
  SET_UPDATED_AT(state, updatedAt) {
    state.updatedAt = updatedAt;
  },
  SET_POINTS(state, { points, zoom, bbox = null }) {
    Vue.set(state.points, zoom, {
      data: points,
      bbox,
      updated_at: Date.now(),
    });
  },
  SET_LINES(state, lines) {
    Vue.set(state, 'lines', {
      data: lines,
      updated_at: Date.now(),
    });
  },
  SET_POLYGONS(state, polygons) {
    Vue.set(state, 'polygons', {
      data: polygons,
      updated_at: Date.now(),
    });
  },
  SET_ACTIONS(state, actions) {
    Vue.set(state, 'actions', {
      data: actions,
      updated_at: Date.now(),
    });
  },
  SET_MODE(state, mode) {
    state.mode = mode;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_DISPLAY_ACTIONS(state, displayActions) {
    state.display_actions = displayActions;
  },
  SET_SELECTED(state, mapObjectIds) {
    state.selected = mapObjectIds;
  },
  RESET_MAP(state) {
    state.updated_at = null;
    state.points = {};
    state.lines = {};
    state.polygons = {};
    state.actions = {};
  },
};

const getters = {
  getUpdatedAt: state => state.updatedAt,
  getPoints: state => state.points,
  getPointsForCurrentZoom: state => zoom => state.points[zoom],
  getLines: state => state.lines,
  getPolygons: state => state.polygons,
  getActions: state => state.actions,
  getMode: state => state.mode,
  getFilter: state => state.filter,
  getDisplayActions: state => state.display_actions,
  getSelected: state => state.selected,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

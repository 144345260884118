<template>
  <CollapsePanel
    :title="$t('route_management.object_information')"
    :loading=loading
    :showCount=false
    :collapsed="true"
    :showCollapseAction="
      !mapObjectLocked && (
      $can('update', 'mapobject') ||
      $can('delete', 'mapobject')
    )"
    icon="pen"
    @collapseAction="toggleShowCollapseMenu"
  >
    <template v-slot:customCollapseAction v-if="showCollapseMenu">
      <CollapseActionMenu>
        <CollapseActionMenuItem
          v-if="$can('update', 'mapobject')"
          :title="$t('button.edit') | capitalize"
          icon="pen"
          @clickItem="toEditObject"
        />
        <CollapseActionMenuItem
          v-if="$can('update', 'mapobject')"
          :title="$t('button.move') | capitalize"
          icon="pen"
          @clickItem="toMoveObject"
        />
        <CollapseActionMenuItem
          v-if="$can('update', 'mapobject')
           && mapObject
            && mapObject.geoObject.data.geometry.type === 'LineString'"
          :title="$t('button.split') | capitalize"
          icon="pen"
          @clickItem="toSplitObject"
        />
        <CollapseActionMenuItem
          v-if="$can('delete', 'mapobject')"
          :title="$t('button.delete') | capitalize"
          icon="trash"
          @clickItem="deleteObject"
        />
      </CollapseActionMenu>
    </template>
    <div v-if="mapObject && mapObject.geoObject && mapObject.geoObject.data"
         class="object-information-block"
    >
      <h3 class="block-header">{{ $t('route_management.location_data') }}</h3>
      <ul class="block-details">
        <li v-if="mapObject.geoObject.data.properties.length" class="block-item">
          <span class="block-item-title">{{ $t('model.length') | capitalize }}:</span>
          <span class="block-item-content">
            {{ length }}
          </span>
        </li>
        <li v-if="mapObject.geoObject.data.properties.area" class="block-item">
          <span class="block-item-title">{{ $t('model.area') | capitalize }}:</span>
          <span class="block-item-content">
            {{ area }}
          </span>
        </li>
        <li v-if="mapObject.geoObject.data.properties.road" class="block-item">
          <span class="block-item-title">{{ $t('model.road') | capitalize }}:</span>
          <span class="block-item-content">
            {{ mapObject.geoObject.data.properties.road }}
            <span v-if="mapObject.geoObject.data.properties.house_number">
              {{ mapObject.geoObject.data.properties.house_number }}
            </span>
          </span>
        </li>
        <li v-if="mapObject.geoObject.data.properties.postcode" class="block-item">
          <span class="block-item-title">{{ $t('model.postcode') | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.geoObject.data.properties.postcode }}</span>
        </li>
        <li v-if="mapObject.geoObject.data.properties.city" class="block-item">
          <span class="block-item-title">{{ $t('model.city') | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.geoObject.data.properties.city }}</span>
        </li>
        <li v-if="mapObject.geoObject.data.properties.city_district" class="block-item">
          <span class="block-item-title">{{ $t('model.city_district') | capitalize }}:</span>
          <span class="block-item-content">
            {{ mapObject.geoObject.data.properties.city_district }}
          </span>
        </li>
        <li v-if="mapObject.geoObject.data.geometry.type === 'Point'" class="block-item">
          <span class="block-item-title">Latitude:</span>
          <span class="block-item-content">
            {{ mapObject.geoObject.data.geometry.coordinates[1] }}
          </span>
        </li>
        <li v-if="mapObject.geoObject.data.geometry.type === 'Point'" class="block-item">
          <span class="block-item-title">Longitude:</span>
          <span class="block-item-content">
            {{ mapObject.geoObject.data.geometry.coordinates[0] }}
          </span>
        </li>
      </ul>
    </div>

    <div v-if="mapObject && mapObject.attribute && mapObject.attribute.data"
         class="object-information-block"
    >
      <h3 class="block-header">{{ $t('route_management.object_data') }}</h3>
      <ul class="block-details">
        <li class="block-item" v-if="user.super_admin">
          <span class="block-item-title">{{ $t('model.id') | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.id }} ({{ mapObject.name }})</span>
        </li>
        <li class="block-item">
          <span class="block-item-title">{{ $t('model.created_at') | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.created_at | dateTime }}</span>
        </li>
        <li class="block-item">
          <span class="block-item-title">{{ $t('model.updated_at') | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.updated_at | dateTime }}</span>
        </li>
        <li v-if="mapObject.mapObjectType && mapObject.mapObjectType.data" class="block-item">
          <span class="block-item-title">{{ $tc('models.type', 1) | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.mapObjectType.data.name }}</span>
        </li>
        <li v-if="mapObject.mapObjectStatus && mapObject.mapObjectStatus.data" class="block-item">
          <span class="block-item-title">{{ $tc('models.status', 1) | capitalize }}:</span>
          <span class="block-item-content">{{ mapObject.mapObjectStatus.data.name }}</span>
        </li>
        <li v-for="(attribute, index) in mapObject.attribute.data" :key="index" class="block-item">
          <span class="block-item-title">{{ attribute.name | capitalize }}:</span>
          <span class="block-item-content"><AttributeValue :attribute="attribute" /></span>
        </li>
        <li class="block-item">
          <span class="block-item-title">{{ $t('model.owner') | capitalize }}:</span>
          <span class="block-item-content">{{ owner_name }}</span>
        </li>
      </ul>
    </div>

    <div v-if="Object.keys(selections).length > 0 || parentSelections.length > 0"
         class="object-information-block"
    >
      <h3 class="block-header">{{ $tc('models.selection', 0) }}</h3>
      <ul class="block-details">
        <li v-for="(selection, type) in selections" :key="type" class="block-item">
          <span class="block-item-title">{{ type }}:</span>
          <span class="block-item-content">{{ selection.join(', ') }}</span>
        </li>
        <div v-for="(parentSelection, type) in parentSelections" :key="type"
             class="parent-selections"
        >
          <h3 class="block-header">{{ parentSelection.name }}</h3>
          <ul class="block-details">
            <li v-for="(innerSelection, innerType) in parentSelection.selections"
                :key="innerType"
                class="block-item"
            >
              <span class="block-item-title">{{ innerType }}:</span>
              <span class="block-item-content">{{ innerSelection.join(', ') }}</span>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </CollapsePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AttributeValue from '@/components/AttributeValue.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import MapObjectApi from '@/api/map_object';

export default {
  name: 'ObjectInformation',
  components: {
    CollapseActionMenuItem,
    CollapseActionMenu,
    AttributeValue,
    CollapsePanel,
  },
  props: {
    mapObject: {
      type: Object,
      required: true,
    },
    mapObjectLocked: Boolean,
    owner_name: String,
  },
  data() {
    return {
      loading: false,
      showCollapseMenu: false,
    };
  },
  methods: {
    ...mapActions({
      resetMapObject: 'selected/resetMapObject',
      resetSelected: 'map/resetSelected',
      removeMapObject: 'settings/removeMapObject',
    }),
    toEditObject() {
      this.$router.push({
        name: 'drawing.edit',
        params: { id: this.mapObject.id },
      });
    },
    toMoveObject() {
      this.$router.push({
        name: 'drawing.move',
        params: { id: this.mapObject.id },
      });
    },
    toSplitObject() {
      this.$router.push({
        name: 'drawing.split',
        params: { id: this.mapObject.id },
      });
    },
    deleteObject() {
      if (!this.$can('delete', 'mapobject')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.mapobject', 1) }),
        });
        return;
      }
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_map_object_confirm'))) {
        const parentId = this.mapObject.parent_id;
        this.loading = true;
        MapObjectApi.deleteMapObjectById(this.mapObject.id, true)
          .then(() => {
            this.loading = false;
            this.removeMapObject({ mapObjectId: this.mapObject.id });
            this.resetMapObject();
            this.resetSelected();
            if (parentId !== null) {
              this.$router.push({ name: 'drawing.show', params: { id: parentId } });
            } else {
              this.$router.push({ name: 'drawing' });
            }
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.delete_failed', { model: this.$tc('models.mapobject', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      network: 'settings/getNetworkModel',
      mapObjectSelectionTypes: 'settings/getMapObjectSelectionTypes',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    length() {
      if (!this.mapObject.geoObject || !this.mapObject.geoObject.data.properties.length) {
        return null;
      }
      const length = parseFloat(this.mapObject.geoObject.data.properties.length);

      if (length > 1100) {
        return `${(length / 1000).toFixed(2)} km`;
      }
      return `${length.toFixed(0)} m`;
    },
    area() {
      if (!this.mapObject.geoObject || !this.mapObject.geoObject.data.properties.area) {
        return null;
      }
      const area = parseFloat(this.mapObject.geoObject.data.properties.area);

      if (area > 1100000) {
        return `${(area / 1000000).toFixed(0)} km2`;
      }
      return `${area.toFixed(0)} m2`;
    },
    selections() {
      if (!this.mapObject.mapObjectSelection || !this.mapObject.mapObjectSelection.data) {
        return {};
      }
      const mapObjectSelections = this.mapObject.mapObjectSelection.data;
      const selections = {};
      mapObjectSelections.forEach((mapObjectSelection) => {
        const typeName = this.mapObjectSelectionTypes[
          mapObjectSelection.map_object_selection_type_id
        ].name;
        if (!selections[typeName]) {
          selections[typeName] = [];
        }
        selections[typeName].push(mapObjectSelection.name);
      });
      return selections;
    },
    parentSelections() {
      if (!this.mapObject.parents || !this.mapObject.parents.data) {
        return {};
      }
      const parents = this.mapObject.parents.data;
      const selections = [];

      parents.forEach((parent) => {
        if (!parent.mapObjectSelection
          || !parent.mapObjectSelection.data
          || parent.mapObjectSelection.data.length === 0) {
          return;
        }
        const parentSelections = {};

        parent.mapObjectSelection.data.forEach((parentSelection) => {
          const selectionTypeName = this.mapObjectSelectionTypes[
            parentSelection.map_object_selection_type_id
          ].name;
          if (!parentSelections[selectionTypeName]) {
            parentSelections[selectionTypeName] = [];
          }
          parentSelections[selectionTypeName].push(parentSelection.name);
        });

        selections.push({
          name: `${this.mapObjectTypes[parent.map_object_type_id].name} ${parent.name}`,
          selections: parentSelections,
        });
      });
      return selections;
    },
  },
};
</script>
<style scoped>
  .object-information-block {
    padding: 0 20px;
  }
  .object-information-block h3.block-header {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    margin: 0 0 5px;
    padding: 0;
    color: #42464c;
  }
  .object-information-block ul.block-details {
    overflow: auto;
    zoom: 1;
    font-size: .85em;
    margin: 0 0 20px 0;
    padding: 0;
  }
  .object-information-block ul.block-details li.block-item {
    line-height: 1.7em;
    position: relative;
    clear: both;
    list-style: none;
  }
  .object-information-block .block-details .block-item .block-item-title {
    display: block;
    float: left;
    width: 40%;
  }
  .object-information-block .block-details .parent-selections {
    margin-left: 30px;
    font-size: 1.1em;
  }
</style>

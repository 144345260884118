<template>
  <div>
    <Loading :visible="!mapObject" />
    <BreadcrumbMapObject v-if="mapObject"
                         :mapObject="mapObject"
                         :extraLabel="$t('button.split')"
                         @clickBack="clickBack"
                         :show-parents="false"
    />
    <CollapsePanel
      :collapsed="true"
      :loading=loading
      :showCount=false
      :title="$t('route_management.object_information')"
    >
      <div v-if="mapObject" class="object-information-block">
        <p>
          {{ $t(`drawing.split_information`) }}
        </p>

        <div class="field">
          <label for="map-object-type-id" class="type-label">
            {{ $t('model.status') | capitalize }}:
          </label>
          <div class="field content">
            <MultiSelect
              id="map-object-type-id"
              :options="Object.values(availableTypes)"
              v-model="type"
              track-by="id"
              label="name"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              :preselect-first="true"
              placeholder=""
              selectLabel=""
              selectedLabel=""
              deselectLabel=""
              @input="updateType"
            />
          </div>
          <span class="input-error">
            {{ errors.type }}
          </span>
        </div>
        <div class="field">
          <label for="name">{{ $t('model.name') | capitalize }}:</label>
          <input
            id="name"
            name="name"
            type="text"
            v-model="name"
            :class="{'error': errors.name !== null}"
            @change="resetError('name')"
            autofocus
          />
          <span class="input-error">
            {{ errors.name }}
          </span>
        </div>
      </div>
    </CollapsePanel>
    <ButtonRow @back="clickBack" @save="splitObject" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MultiSelect from 'vue-multiselect';
import MapObjectApi from '@/api/map_object';
import Loading from '@/components/Loading.vue';
import BreadcrumbMapObject from '@/components/Layout/BreadcrumbMapObject.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import ButtonRow from '@/components/ButtonRow.vue';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import GeoType from '@/enums/GeoType';

export default {
  name: 'drawing.split_object',
  components: {
    ButtonRow,
    CollapsePanel,
    BreadcrumbMapObject,
    Loading,
    MultiSelect,
  },
  computed: {
    ...mapGetters({
      mapObject: 'selected/getMapObject',
      mapObjectTypes: 'settings/getMapObjectTypes',
      network: 'settings/getNetworkModel',
    }),
    availableTypes() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      return Object.values(this.mapObjectTypes)
        .filter(item => item.geo_type === GeoType.point)
        .filter(item => item.on_network);
    },
  },
  data() {
    return {
      loading: false,
      chosenIndex: null,
      type: null,
      name: '',
      errors: {
        name: null,
        type: null,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      resetMapObject: 'selected/resetMapObject',
      addMapObject: 'settings/addMapObject',
      updateMapObject: 'settings/updateMapObject',
    }),
    clickBack() {
      EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
      this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } });
    },
    splitObject() {
      if (!this.validateFields()) {
        return;
      }
      if (!this.chosenIndex) {
        this.$notify({
          type: 'error',
          title: this.$t('drawing.split_error_no_index_chosen'),
        });
        return;
      }
      this.loading = true;

      const data = {
        data: {
          coordinateIndex: this.chosenIndex,
          name: this.name,
          map_object_type_id: this.type.id,
        },
      };

      MapObjectApi.splitMapObject(this.mapObject.id, data)
        .then(async (response) => {
          response.data.forEach((object) => {
            if (object.id !== this.mapObject.id) {
              this.addMapObject({ mapObject: object });
            }
          });
          await this.fetchMapObject({ id: this.mapObject.id, force: true });
          this.loading = false;
          this.$notify({
            type: 'success',
            title: this.$t('dialog.created', {
              model: this.$options.filters.capitalize(this.$tc('models.mapobject', 1)),
              name: this.name,
            }),
          });
          EventBus.$emit('Map.redraw');
          EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
          return this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
          this.loading = false;
          return false;
        });
    },
    resetError(field) {
      this.errors[field] = null;
    },
    validateFields() {
      let valid = true;
      if (this.name === '') {
        this.errors.name = this.$t('validation.name_empty');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('validation.name_empty'),
          duration: 1000,
        });
        valid = false;
      }
      if (this.type === null) {
        this.errors.name = this.$t('validation.field_empty', { field: this.$tc('models.mapobjecttype', 1) });
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('validation.field_empty', { field: this.$tc('models.mapobjecttype', 1) }),
          duration: 1000,
        });
        valid = false;
      }
      return valid;
    },
    fetchNameForNewObject(typeId) {
      MapObjectApi.getNameForNewObject(typeId).then((response) => {
        if (response && response.data && response.data.name) {
          this.name = response.data.name;
        }
      });
    },
    updateType(value) {
      if (!value) {
        this.type = null;
      } else {
        this.type = value;
        if (value.id) {
          this.fetchNameForNewObject(value.id);
        }
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      const id = parseInt(this.$route.params.id, 10);
      if (!this.mapObject || this.mapObject.id !== id) {
        this.fetchMapObject({ id });
      }
    }
    EventBus.$emit(
      'Map.changeMapMode',
      MapMode.splitLine,
    );

    EventBus.$on('Map.shareSplitLineIndex', (index) => {
      this.chosenIndex = index;
    });
  },
};
</script>

<style scoped>
.object-information-block {
  padding: 0 20px 40px 20px;
  overflow: inherit;
}
.field {
  overflow: visible;
  min-height: 40px;
}
.field label {
  width: auto;
}
.type-label {
  display: inline-flex;
  float: initial;
}
.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}
input.error {
  border-color: #ff7676;
}
</style>
